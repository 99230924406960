/* Palette */
/* Brand colors */
/* Links */
.__booya-link {
  color: #0067a6 !important;
}
.__booya-link:focus, .__booya-link:hover {
  text-decoration: underline;
}

/* Buttons */
.__booya-btn,
.__booya-btn:link,
.__booya-btn:visited {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-align: center;
  color: #fff;
  background-color: #0067a6;
  padding: 12px 15px;
  margin: 10px 8px;
  border: 1px #0067a6 solid;
  border-radius: 3px;
  cursor: pointer;
}

.__booya-btn:hover,
.__booya-btn:focus {
  color: #fff;
  background-color: #0067a6;
  border-color: #0067a6;
  opacity: 0.5;
  text-decoration: none;
}

.__booya-btn-outline,
.__booya-btn-outline:link,
.__booya-btn-outline:visited {
  color: #0067a6;
  background-color: #fff;
  border-color: #0067a6;
}

.__booya-btn-outline:hover,
.__booya-btn-outline:focus {
  color: #0067a6;
  background-color: #E2FAFF;
}

.__booya-btn-signin {
  display: block !important;
  margin: 10px 0 !important;
  background-color: #fff !important;
}
.__booya-btn-signin img {
  height: 20px;
  vertical-align: middle;
  margin-right: 10px;
}
.__booya-btn-signin.__booya-btn-signin-google {
  border-color: #dd4b39 !important;
  color: #dd4b39 !important;
}
.__booya-btn-signin.__booya-btn-signin-facebook {
  border-color: #3b5999 !important;
  color: #3b5999 !important;
}
.__booya-btn-signin.__booya-btn-signin-linkedin, .__booya-btn-signin.__booya-btn-signin-linkedin2 {
  border-color: #007bb6 !important;
  color: #007bb6 !important;
}
.__booya-btn-signin.__booya-btn-signin-twitter {
  border-color: #00acee !important;
  color: #00acee !important;
}
.__booya-btn-signin.__booya-btn-signin-email {
  border-color: #000 !important;
  color: #000 !important;
}

/* Forms */
form.__booya-form {
  width: 100%;
  max-width: 620px;
  margin: 0 auto;
}
form.__booya-form h3 {
  margin-bottom: 16px;
}
form.__booya-form a:not(.__booya-btn) {
  text-decoration: underline;
}
form.__booya-form fieldset {
  margin-bottom: 25px;
  padding: 0;
  position: relative;
  border: none;
}
form.__booya-form fieldset.checkbox {
  padding-left: 30px;
}
form.__booya-form fieldset.checkbox label {
  font-weight: 400;
}
form.__booya-form fieldset.checkbox label a {
  color: #00a4bd;
  text-decoration: underline;
}
form.__booya-form fieldset label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  display: inline-block;
}
form.__booya-form fieldset label span.optional {
  font-weight: 300;
  font-style: italic;
  letter-spacing: normal;
}
form.__booya-form fieldset label legend {
  font-size: 12px;
  font-weight: 300;
  color: #cbd6e2;
  margin-top: -4px;
}
form.__booya-form fieldset .input {
  position: relative;
}
form.__booya-form fieldset p {
  margin-bottom: 0;
}
form.__booya-form fieldset .error {
  font-size: 12px;
  font-weight: 300;
  color: #f2545b;
  top: calc(100% + 2px);
  position: absolute;
}
form.__booya-form fieldset .error.info {
  color: #fff;
}
form.__booya-form input, form.__booya-form textarea, form.__booya-form select {
  appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  resize: none;
  display: block;
  width: 100%;
  min-height: 40px;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  vertical-align: middle;
  color: #33475b;
  background-color: #f5f8fa;
  padding: 9px 10px;
  border-radius: 3px;
  border: 1px solid #cbd6e2;
  transition: all 0.15s ease-out;
  box-sizing: border-box;
}
form.__booya-form input:focus, form.__booya-form input.focus, form.__booya-form textarea:focus, form.__booya-form textarea.focus, form.__booya-form select:focus, form.__booya-form select.focus {
  border-color: rgba(0, 208, 228, 0.5);
  box-shadow: 0 0 4px 1px rgba(0, 208, 228, 0.3), 0 0 0 1px #00d0e4;
  outline: 0;
}
form.__booya-form textarea {
  height: 120px;
  resize: none;
}
form.__booya-form select {
  width: 100%;
  font-size: 16px;
  border-radius: 3px;
  border: solid 1px #cbd6e2;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  background: #fff url(https://cdn.jsdelivr.net/npm/@inboundlabs/booya-ui@0.1/dist/images/dropdown.svg) no-repeat right center;
  background-position-x: calc(100% - 8px);
  color: #33475b;
  font-weight: 400;
}
form.__booya-form input[type=checkbox] {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: solid 1px #c7d0da;
  background-color: #fff;
  padding: 0;
  vertical-align: middle;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  margin-right: 8px;
}
form.__booya-form input[type=checkbox] + span {
  position: absolute;
  left: 0;
  top: 0;
}
form.__booya-form input[type=checkbox] + span:before {
  position: absolute;
  content: "";
  width: 17px;
  height: 17px;
  border-radius: 2px;
  border: solid 1px #c7d0da;
  background-color: #ffffff;
  padding: 0;
  vertical-align: middle;
  z-index: 0;
}
form.__booya-form input[type=checkbox]:checked + span:before {
  border-color: #00a4bd;
  background-size: 106% 106%;
  background: url(https://cdn.jsdelivr.net/npm/@inboundlabs/booya-ui@0.1/dist/images/checkbox.svg) no-repeat;
  background-position-x: -1px;
  background-position-y: -1px;
}
form.__booya-form button {
  width: 100%;
  padding-left: 46px;
  padding-right: 46px;
  margin-top: 10px;
}
form.__booya-form button[disabled] {
  cursor: not-allowed;
}
form.__booya-form .has-error input, form.__booya-form .has-error input:focus,
form.__booya-form .has-error textarea, form.__booya-form .has-error textarea:focus,
form.__booya-form .has-error select, form.__booya-form .has-error select:focus {
  box-shadow: 0 0 0 1px #f2545b !important;
}
form.__booya-form .upload-file.has-error .file-widget-wrapper .file-widget {
  box-shadow: 0 0 0 1px #f2545b !important;
}
form.__booya-form .__booya-form-action button {
  margin-left: 0;
  margin-right: 0;
}
form.__booya-form .form-success,
form.__booya-form .form-error,
form.__booya-form .form-validation-error,
form.__booya-form .form-info,
form.__booya-form .form-warn {
  font-size: 16px;
  background-color: #eee;
  padding: 8px 12px;
  margin: 20px 0;
  border-radius: 3px;
}
form.__booya-form .form-success {
  color: #1e4620;
  background-color: #edf7ed;
}
form.__booya-form .form-warn {
  color: #663c00;
  background-color: #fff4e5;
}
form.__booya-form .form-info {
  color: #0d3c61;
  background-color: #e8f4fd;
}
form.__booya-form .form-error,
form.__booya-form .form-validation-error {
  color: #611a15;
  background-color: #fdecea;
}

/* Auth Widgets */
.__booya-auth-widgets-wrapper {
  border: 1px solid #ddd;
  width: 400px;
  margin: 0 auto;
  padding: 30px 40px;
}
.__booya-auth-widgets-wrapper .__booya-auth-widget-section-switch-wrapper {
  margin-top: 30px;
}
.__booya-auth-widgets-wrapper .__booya-form > h1, .__booya-auth-widgets-wrapper .__booya-form > h2, .__booya-auth-widgets-wrapper .__booya-form > h3, .__booya-auth-widgets-wrapper .__booya-form > h4, .__booya-auth-widgets-wrapper .__booya-form > h5, .__booya-auth-widgets-wrapper .__booya-form > h6 {
  text-align: center;
}

.__booya-section-separator {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
.__booya-section-separator .__booya-section-separator-line {
  flex-grow: 1;
  height: 1px;
  background-color: #cbd6e2;
}
.__booya-section-separator .__booya-section-separator-text {
  padding: 0 10px;
}

/* Modals */
.__booya-modal-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition-property: none;
  transition-delay: 0ms;
  transition-duration: 100ms;
  background-color: rgba(45, 62, 80, 0.79);
  padding-bottom: 60px;
  padding-top: 60px;
  overflow-x: hidden;
  z-index: 1000;
}
.__booya-modal-overlay .__booya-modal {
  width: 400px;
  max-width: 90%;
  opacity: 1;
  background-color: #fff;
  text-align: left;
  border-radius: 3px;
  margin: 0 auto;
}
.__booya-modal-overlay .__booya-modal .__booya-modal-header {
  position: relative;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #0067a6;
  background-color: transparent;
  padding: 20px 40px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.__booya-modal-overlay .__booya-modal .__booya-modal-header > *:not(.__booya-close) {
  color: #0067a6;
  margin: 0;
}
.__booya-modal-overlay .__booya-modal .__booya-modal-header .__booya-close {
  position: absolute;
  right: 24px;
  top: 24px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  color: #333;
}
.__booya-modal-overlay .__booya-modal .__booya-modal-header .__booya-close svg {
  color: inherit;
  display: block;
}
.__booya-modal-overlay .__booya-modal .__booya-modal-header .__booya-close svg path {
  fill: currentcolor;
  stroke: currentcolor;
  stroke-width: 2;
}
.__booya-modal-overlay .__booya-modal .__booya-modal-content {
  padding: 40px;
  font-size: 14px;
}
.__booya-modal-overlay .__booya-modal form.__booya-form .form-success,
.__booya-modal-overlay .__booya-modal form.__booya-form .form-error,
.__booya-modal-overlay .__booya-modal form.__booya-form .form-validation-error,
.__booya-modal-overlay .__booya-modal form.__booya-form .form-info,
.__booya-modal-overlay .__booya-modal form.__booya-form .form-warn {
  margin-top: 0;
}
.__booya-modal-overlay .__booya-modal .__booya-auth-widgets-wrapper {
  width: auto;
  padding: 0;
  border: none;
}

/* Account Verification */
.__booya-email-verification-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  font-size: 14px;
  color: #fff;
  background-color: #f44336;
  margin: 0;
  padding: 20px;
  border-radius: 0;
  z-index: 999999;
}
.__booya-email-verification-banner.__booya-email-verification-banner-success {
  background-color: #4caf50;
}
.__booya-email-verification-banner .page-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.__booya-email-verification-banner .__booya-email-verification-banner-message {
  flex-grow: 1;
}
.__booya-email-verification-banner .__booya-email-verification-banner-message a {
  color: #fff;
  text-decoration: underline;
}
.__booya-email-verification-banner .__booya-email-verification-banner-close {
  cursor: pointer;
}

.__booya-verification-status {
  font-size: 16px;
  text-align: center;
  padding: 0 16px 16px;
  border-radius: 3px;
}
.__booya-verification-status img {
  display: block;
  height: 70px;
  margin: 0 auto 8px;
}

.__booya-verification-status-error {
  color: #f2545b;
}

.__booya-verification-status-success {
  color: #0067a6;
}

/* User Widget */
.__booya-user-widget-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#__booya-user-widget {
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
}
#__booya-user-widget:after {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  box-shadow: -7px -7px 18px 0 rgba(0, 0, 0, 0.08);
  background: #fff;
  transform: rotate(45deg);
  right: 20px;
  top: calc(100% - 6px);
  z-index: 999999;
  opacity: 0;
  transition: all ease-in-out 0.3s;
  border-left: 1px solid #cbd6e2;
  border-top: 1px solid #cbd6e2;
}
#__booya-user-widget:hover:after, #__booya-user-widget:focus:after {
  opacity: 1;
}
#__booya-user-widget > img, #__booya-user-widget > div > img {
  height: 24px;
  width: 24px;
  margin-right: 4px;
  vertical-align: text-bottom;
  border-radius: 100%;
}
#__booya-user-widget .__booya-user-name {
  display: inline-block;
  position: relative;
  top: 2px;
  max-width: 120px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
#__booya-user-widget:hover .__booya-user-widget-options, #__booya-user-widget:focus .__booya-user-widget-options {
  display: block;
}
#__booya-user-widget .__booya-user-widget-options {
  display: none;
  position: absolute;
  top: calc(100% - 1px);
  right: 0;
  width: 160px;
  max-width: 100vw;
  max-height: 280px;
  text-align: left;
  color: #33475b;
  background-color: #fff;
  padding: 8px 0;
  border: 1px solid #cbd6e2;
  border-radius: 3px;
  box-shadow: 0 1px 24px 0 rgba(0, 0, 0, 0.08);
  overflow-y: auto;
  z-index: 4;
}
#__booya-user-widget .__booya-user-widget-options .__booya-user-widget-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  margin: 0;
  cursor: pointer;
  box-sizing: border-box;
}
#__booya-user-widget .__booya-user-widget-options .__booya-user-widget-option:hover {
  background-color: #e5f5f8;
}

/* Banners */
.__booya-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  font-size: 16px;
  color: #fff;
  background-color: #0067a6;
  margin: 0;
  padding: 20px;
  border-radius: 0;
  z-index: 999999;
}
.__booya-banner.__booya-banner-success {
  background-color: #4caf50;
}
.__booya-banner.__booya-banner-info {
  background-color: #2196f3;
}
.__booya-banner.__booya-banner-warn {
  background-color: #ff9800;
}
.__booya-banner.__booya-banner-danger {
  background-color: #f44336;
}
.__booya-banner .__booya-banner-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 90%;
  margin: 0 auto;
}
.__booya-banner .__booya-banner-message {
  flex-grow: 1;
}
.__booya-banner .__booya-banner-message a {
  color: #fff !important;
  text-decoration: underline;
}
.__booya-banner .__booya-banner-close {
  cursor: pointer;
}
.__booya-banner .__booya-banner-close img {
  width: 16px;
}


